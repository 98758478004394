import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import swal from '@sweetalert/with-react'

import { NotFound } from '../../NotFound'
import { browserHistory } from 'react-router'

import SectionTitle from './SectionTitle'
import FieldList from './FieldList'
import { FormNavigator } from '../FormNavigator'
import { BatchAdjustmentForm } from '../BatchAdjustment'
import LoadingMessage from '../../LoadingMessage/LoadingMessage'
import useFormSection from '../../../hooks/useFormSection'
import { isAdmin } from '../../../utils/utils'
import {
  FieldInstanceProps,
  FormInstanceProps,
  FormTemplateProps,
} from '../../../formSchema'
import * as FormInstanceActions from '../../../actions/formInstanceAction'
import FormCommentsContainer from './FormComments'
import { BillOfMaterials } from '../BillOfMaterials'
import { getPageSection } from '../../../utils/activePageSection'

type StateProps = {
  submitForm: () => void
  user: any
  index: number
  formInstance: FormInstanceProps
  formTemplate: FormTemplateProps
  formFieldInstances: FieldInstanceProps[]
  params: { page: string; id: string }
}

type DispatchProps = {
  formInstanceActions: typeof FormInstanceActions
}

type Props = StateProps & DispatchProps


const getPreviousSection = (formInstance, index) => {
  const prevSection = formInstance.section_instance[index];

  if (!prevSection) return false

  if (!prevSection.super_order_index) {
    return getPreviousSection(formInstance, index - 1)
  }

  return prevSection
}

const getNextSection = (formInstance, index) => {
  const nextSection = formInstance.section_instance[index];

  if (!nextSection) return false

  if (!nextSection.super_order_index) {
    return getPreviousSection(formInstance, index + 1)
  }

  return nextSection
}

const FormSection = ({
  user,
  formInstance,
  formInstanceActions,
  submitForm,
  params
}: Props) => {
  const {
    fieldInstances,
    handleFieldUpdate,
    clearFieldInstances,
    saving,
    error,
    validateSection,
    batchAdjust,
    toggleBatchAdjust,
    addAdjustmentInstances,
  } = useFormSection({
    user,
    formInstance,
    formInstanceActions
  })

  // TODO: MIGRATE TO SOPFORMPAGE
  // Get the page index from the URL as an integer
  const page = parseFloat(params.page)

  // Get the section based on the page index
  const sectionInstance = useMemo(() => getPageSection(formInstance, page) || { section: false}, [formInstance, page])


  // TODO: MIGRATE TO SOPFORMPAGE
  // If no form fields are not found
  if (sectionInstance.section && !fieldInstances) return <NotFound />

  // TODO: MIGRATE TO SOPFORMPAGE
  // If form data is not loaded
  if (fieldInstances.length <= 0) return <LoadingMessage />




  // TODO: MIGRATE TO SOPFORMPAGE
  // Get the max page index
  // This is based off of sections without batch_adjustment
  // TODO: This should be based off of if there is a order_index for a section
  const maxPageIndex = formInstance.section_instance.filter(
    (section) => !section.section.features.batch_adjustment
  ).length


  const standardYield =
  formInstance.global_fields.find((field) => field.name === 'Standard Yield')
    ?.value || ''

  const returnToDashboard = () => {
    // Clear field instances
    clearFieldInstances()

    // Return to dashboard
    browserHistory.push(`/forms`)
  }

  const handleBack = () => {
    const activeIndex = formInstance.section_instance.findIndex(instance => sectionInstance.id === instance.id)

    if (activeIndex === 0) {
      returnToDashboard()
      return
    }

    const prevSection = getPreviousSection(formInstance, activeIndex - 1);

    if (!prevSection) {
      returnToDashboard()
      return
    }

    clearFieldInstances()
    browserHistory.push(`/forms/${formInstance.id}/${prevSection.super_order_index}`) // Update URL with the new step
  }

  const handleNext = () => {
    if (!validateSection()) return

    if (page === maxPageIndex) {
      submitForm()
    } else {

      const activeIndex = formInstance.section_instance.findIndex(instance => sectionInstance.id === instance.id)
      const nextSection = getNextSection(formInstance, activeIndex + 1);

      if (!nextSection) {
        submitForm();
        return
      }

      clearFieldInstances()
      // updateFormInstance({active_section_id: nextSection.id})

      browserHistory.push(`/forms/${formInstance.id}/${nextSection.super_order_index}`) // Update URL with the new step
    }
  }

  // If formIndex is within the valid range (0 to maxPageIndex), we display the NotFound component.
  //  This could be intentional to handle an edge case or to prevent navigation beyond the available sections.
  if (page > maxPageIndex) {
    return <NotFound />
  }

  const handleBOM = () => {
    return swal(<BillOfMaterials formInstance={formInstance} />, {
      buttons: false,
      className: 'tw-w-fit'
    })
  }

  const Navigator = () => (
    <FormNavigator
      index={page}
      maxIndex={maxPageIndex}
      onBack={handleBack}
      onNext={handleNext}
    >
      <div className="mr-4">
        {error && <p className="mb-0 text-danger">{error}</p>}
        <p className="mb-0">
          {saving ? (
            'Saving...'
          ) : (
            <>
              Saved:{' '}
              {moment(formInstance.updated_at).format('MM/DD/YYYY h:mm:ss a')}
            </>
          )}
        </p>
      </div>
    </FormNavigator>
  )

  if (batchAdjust) {
    return (
      <>
        <BatchAdjustmentForm
          batchAdjustmentFieldInstances={fieldInstances}
          formTemplate={formInstance.form_template}
          handleFieldUpdate={handleFieldUpdate}
          addAdjustmentInstances={addAdjustmentInstances}
        />
        <FormNavigator
          index={1}
          maxIndex={1}
          onBack={toggleBatchAdjust}
          onNext={toggleBatchAdjust}
        >
          <div className="mr-4">
            {error && <p className="mb-0 text-danger">{error}</p>}
            <p className="mb-0">
              {saving ? (
                'Saving...'
              ) : (
                <>
                  Saved:{' '}
                  {moment(formInstance.updated_at).format(
                    'MM/DD/YYYY h:mm:ss a'
                  )}
                </>
              )}
            </p>
          </div>
        </FormNavigator>
      </>
    )
  }

  return (
    <>
      <section className="bg-white flex-fill">
        <div className="sop-form">
          <SectionTitle
            isAdmin={isAdmin(user)}
            section={sectionInstance.section}
            showBom={handleBOM}
            showAdjustment={toggleBatchAdjust}
            returnToDashboard={returnToDashboard}
          />
          <FieldList
            fieldInstances={fieldInstances}
            standardYield={standardYield}
            handleFieldUpdate={handleFieldUpdate}
          />

          <FormCommentsContainer />
        </div>
      </section>
      <Navigator />
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  user: state.authReducer.user,
  formTemplate: state.formTemplateReducer[0],
  formInstance: state.formInstanceReducer.activeForm
})

const mapDispatchToProps = (dispatch) => ({
  formInstanceActions: bindActionCreators(FormInstanceActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(FormSection)
