import React from 'react';

const NewAssetGroupView = () => {
  const assetGroups = [
    { title: 'Asset Group 1', description: 'Paints' },
    { title: 'Asset Group 2', description: 'Yellows' },
    { title: 'Asset Group 3', description: 'Smalls' },
    { title: 'Asset Group 4', description: 'Bigs' },
    { title: 'Asset Group 4', description: 'Lefts' },
    { title: 'Unassigned Assets', description: '' },
  ];
  return (
    <main className='app-content'>
      <div className='app-title border-bottom'>
        <h1 className='tw-font-extrabold'>Asset Groups</h1>
      </div>
      {/* Button here */}
      <div className='tw-grid tw-grid-cols-4 tw-w-[1200px] tw-gap-5'>
        {assetGroups.map((group, index) => (
          <button
            key={index}
            className='tw-bg-white border tw-border-gray-400 tw-rounded-md tw-mr-10 tw-p-8'
          >
            <h1 className='tw-text-black tw-font-bold tw-text-lg tw-flex tw-justify-start'>
              {group.title}
            </h1>
            <p className='tw-font-medium tw-flex tw-justify-start'>
              {group.description}
            </p>
          </button>
        ))}
      </div>
    </main>
  );
};

export default NewAssetGroupView;
