import React from 'react'
import { connect } from 'react-redux'

export const SectionTitle = ({
  features,
  isAdmin,
  section,
  showBom,
  showAdjustment
}) => {
  const { batch_adjustments, bom_page } = features
  return (
    <div className="pb-3 mb-4 border-bottom d-flex justify-content-between align-items-center">
      <div>
        <p
          className={`sop-section-title ${
            section.description && 'sop-section-title--sm'
          }`}
        >
          {section.name}
        </p>
        {section.description && (
          <p className="sop-section-subtitle">{section.description}</p>
        )}
      </div>

      <div>
        {batch_adjustments && (isAdmin || section.show_adjustment_btn) && (
          <button onClick={showAdjustment} className="btn btn-primary">
            Add Adjustments
          </button>
        )}
        {bom_page && (
          <button onClick={showBom} className="ml-4 btn btn-outline-primary">
            View BOM
          </button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  features: state.formInstanceReducer.activeForm.form_template.features
})

export default connect(mapStateToProps)(SectionTitle)
