import React from 'react'

interface InstructionProps {
  value: string
  standardYield: string
  systemOfUnits?: string
}

export const round = (value: number) => {
  return Math.round((value + Number.EPSILON) / 5) * 5
}

export const floor = (value: number) => {
  return Math.floor((value + Number.EPSILON) / 5) * 5
}
export const ceil = (value: number) => {
  return Math.ceil((value + Number.EPSILON) / 5) * 5
}

export const Instruction = ({
  value,
  standardYield,
  systemOfUnits = 'imperial'
}: InstructionProps) => {
  // Implement conversion to weight using
  // *** organization unit system
  // *** standard yield, weight, for current batch

  // TODO: pass in some validation to convert weights

  let weight = 0
  let deviation = 0
  const percentIndex = value.indexOf('%')
  const units = systemOfUnits === 'imperial' ? 'lbs' : 'kg'

  if (percentIndex > -1) {
    const percent = parseFloat(value.slice(0, percentIndex)) / 100
    weight = round(percent * parseInt(standardYield))

    // TODO: Get deviation from validation
    deviation = weight * 0.001
  }

  return (
    <p className="sop-instruction tw-text-lg">
      {/* {weight} {units} of {value.slice(percentIndex + 1, value.length)}{' '} */}
      {floor(weight - deviation)} - {ceil(weight + deviation)} {units} of {value.slice(percentIndex + 1, value.length)}{' '}
      <span className="font-italic font-weight-normal">
        - {value.slice(0, percentIndex)}%
      </span>
      {/* <br />
      <span className="font-weight-normal">
        {floor(weight - deviation)} - {ceil(weight + deviation)} {units}
      </span> */}
    </p>
  )
}
