

export const form_roles: string[] = [
    "Operator",
    "Approver",
];

export interface EmpiricUser {
    id: string,
    email: string,
    first_name: string,
    last_name: string,
    form_role?: string,
    access_id?: number,
    _destroy?: boolean,
}

interface formTemplateStatus {
    id: number,
    name: string
}

export const form_template_status: formTemplateStatus[] = [
    { id: 1, name: "active" },
    { id: 2, name: "inactive" },
];

export const form_status = [
    { id: 1, name: "Ready to start" },
    { id: 2, name: "In Progress" },
    { id: 3, name: "Complete" },
    { id: 4, name: "rejected" },
];

// interface formAccess {
//     id: number,
//     users: number[],
//     form_role_id: number,
//     form_instance_id: number,
// }

export interface ProductProps {
    organization_id: number,
    name: string,
    description: string
    number: string,
    formula_code: string,
    version: string
}

export interface FormTemplateProps {
    id: number,
    organization_id: number,
    product: ProductProps,
    create_at: string,
    version: string,
    status: string,
    system_of_units?: string,
    temperature_unit?: string,
    sections: FormSectionProps[],
    features: {
        batch_adjustments?: boolean,
        bom_page?: boolean,
    }
}


export interface PDFFields extends Omit<FormFieldProps, 'children'>, FieldInstanceProps {
    value: string;
}

export interface FormSectionProps {
    id: string,
    name: string,
    description?: string,
    fields: number[],
    field_instances: PDFFields[] ,
    order_index: number,
    features: {
        batch_adjustment?: boolean,
        show_adjustment_btn?: boolean,
    }
}

export interface CommentProps {
    value: string
    first_name: string
    last_name: string
    email: string
    created_at: string
  }


// Field Types:
// - Group
// - Text
// - Number
// - Date
// - Time
// - Datetime-local
// - Select
// - Textarea
// - Signature
// - Read-only

export interface FormFieldProps {
    features: {
        group?: boolean,
        select_options?: string[],
        horizontal?: boolean,
    };
    id: string,
    name: string,
    description?: string,
    field_type: string,
    order_index?: number,
    is_required?: boolean,
    validation_rules?: {
        validation_type: string,
        validation_value: number[],
    }
    parent_id?: string,
    section_id: string,
    parent?: FormFieldProps,
    children?: FormFieldProps[],
    value?: string,
}

export interface SectionInstanceProps {
    id: string,
    form_instance_id: number,
    section_id: number,
    super_order_index: string,
    section: FormSectionProps,
}

export interface FormInstanceProps {
    id: string,
    organization_id: number,
    form_template_id: number,
    active_section_id: string,
    section_instance: SectionInstanceProps[],
    created_by: number,
    created_at: string,
    updated_by: number,
    updated_at: string,
    archived_by: number | null,
    archived_at: string | null,
    status: string,
    version: number,
    adjustment_required?: boolean,
    work_center?: FieldInstanceProps,
    lot_number?: FieldInstanceProps,
    standard_yield: FieldInstanceProps,
    form_comments: CommentProps[],
    // field_instances: FieldInstanceProps[],
    section?: FieldInstanceProps[],
    form_template: FormTemplateProps,
    global_fields: FormFieldProps[],
    header_fields: FormFieldProps[],
}

export interface FieldInstanceProps {
    id: string,
    value: string,
    form_instance_id: number,
    field: FormFieldProps,
    field_id: number,
    created_by: number,
    created_at: string,
    updated_by: number,
    updated_at: string,
    validation_rules?: {
        validation_type: string,
        validation_value: number[],
    }
    error?: string,
    children?: FieldInstanceProps[],
}
