
// SORT Field Instances
export const SORT_FIELD_INSTANCES = (a, b) =>
  SORT_BY_ORDER_INDEX(a.field, b.field)

// Sort Fields by Order Index
export const SORT_BY_ORDER_INDEX = (a, b) =>
  a.order_index && b.order_index ? a.order_index - b.order_index : 0


// Map Field Instances
// This to get group child fields
export const mapToFieldInstances = (fieldInstances, children) => {
  return children
    .sort(SORT_BY_ORDER_INDEX)
    .map((field) =>
      fieldInstances.find((instance) => instance.field.id === field.id)
    )
}

export const findMissingFields = (fieldInstances) => {
  return fieldInstances
    .filter((field) => !field.value && field.field.is_required)
    .map((field) => ({ ...field, error: 'Required' }))
}

// TODO: Handle validation rules
