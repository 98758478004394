import { FormInstanceProps, SectionInstanceProps } from "../formSchema";

export const getActiveSectionInstance = (form: FormInstanceProps): SectionInstanceProps => {
    // If we dont have an active_section_id
    if (!form.active_section_id) {
        // Set active_section_id to the first section
        form.active_section_id = form.section_instance[0].id;
    }

    // If we don't have a specified page number
    const activeSectionInstance = form.section_instance.find(
        (section) => section.id === form.active_section_id
    ) as SectionInstanceProps;


    return activeSectionInstance;
}
