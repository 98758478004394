//This component Automatically formatting number fields - Forms
import React, { useState } from 'react'
import { horizontalClasses } from '../FormFields'
import { FieldError } from './FieldError'

const FormatNumbersFields = ({ label, value, onChange, onBlur, classes, error }) => {
  const [rawValue, setRawValue] = useState(value)

  // This is a function that format number with commas
  const formatNumbersForms = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const handleChange = (event) => {
    let value = event?.target.value

    // Validate the numbers and keep only numeric values
    let numericValue = value.replace(/[^0-9]/g, '')

    // Then Update the raw value state
    setRawValue(numericValue) //ui
    onChange(numericValue) // data on the database
  }

  return (
    <div className={`my-3  ${classes || 'col-sm-6'}`}>
      <div className={`form-input`}>
        <div className="tw-pb-1 tw-flex tw-items-center">
          {label && <label className="mb-0">{label}:</label>}
          {error && <FieldError error={error} />}
        </div>

        <input
          className="form-control"
          type="text"
          value={formatNumbersForms(rawValue)}
          onChange={handleChange}
          onBlur={onBlur}
        />
      </div>
    </div>
  )
}

export default FormatNumbersFields
