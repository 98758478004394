import React from 'react'
import { horizontalClasses } from '../FormFields'
import Select from 'react-select'
import { FieldError } from './FieldError'

interface SOPSelectProps {
  options: any
  onChange: (value: string) => void
  label?: string
  horizontal?: boolean
  classes?: string
  value?: string
  error?: string
}

export const SOPSelect = ({
  value,
  options,
  onChange,
  label,
  horizontal,
  classes,
  error
}: SOPSelectProps) => {
  return (
    <div className={`my-3 ${classes || 'col-sm-6'}`}>
      <div
        className={`form-input ${
          horizontal && horizontalClasses
        }`}
      >
        <div className="tw-pb-1 tw-flex tw-items-center">
          {label && <label className="mb-0">{label}:</label>}
          {error && <FieldError error={error} />}
        </div>
        <Select
          options={options.value.map((option) => ({
            value: option,
            label: option
          }))}
          value={
            value
              ? { value: value, label: value }
              : { value: '', label: '-Select-' }
          }
          // TODO : Handle select change uniquely
          onChange={(value) => onChange(value?.value || '')}
          placeholder="-Select-"
          className="form-group__field form-control-input"
          classNames={{
            control: () => (error ? 'border border-danger' : '')
          }}
          styles={{
            control: (provided) => ({
              ...provided,
              height: '30px',
              minHeight: '30px'
            }),
            valueContainer: (provided) => ({
              ...provided,
              height: '30px',
              padding: '0 6px'
            }),
            input: (provided) => ({
              ...provided,
              height: '30px',
              margin: '0px'
            }),
            indicatorsContainer: (provided, state) => ({
              ...provided,
              height: '30px'
            })
          }}
        />
      </div>
    </div>
  )
}
