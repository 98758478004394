import React from 'react'
import FieldTypes from '../FormFields'
import {
  FieldInstanceProps
} from '../../../formSchema'
import { mapToFieldInstances } from '../../../utils/formUtils'

type FieldGroupProps = {
  instance: FieldInstanceProps
  groupInstances: FieldInstanceProps[]
  standardYield?: string
  onChange: ({ id, value }: { id: string; value: string }) => void
}

export const FieldGroup = ({
  instance,
  groupInstances,
  standardYield,
  onChange
}: FieldGroupProps) => {
  // Test groups
  if (instance.field.name.includes('test')) {
    return (
      <div className="my-2 tw-items-end row row-cols-5">
        {groupInstances.map((instance, index) => (
          <div className="px-2">
            <FieldTypes
              instance={instance}
              key={index}
              classes={'test-col !tw-my-0'}
              onChange={onChange}
            />
          </div>
        ))}
      </div>
    )
  }

  // Group classes
  const tableHeaderClass = instance.field.name.includes('table-heading') && 'tw-bg-[#E6EEFE]'
  const verticalFormGroup = !instance.field.features.horizontal && 'flex-column align-items-start'

  // Individual Field classes
  const colSize = Math.round(12 / groupInstances.length)
  const colClass = `col-sm-${colSize}`


  return (
    <div
      className={`${tableHeaderClass || ''} ${verticalFormGroup || ''} form-group my-n2`}
    >
      {groupInstances.map((instance) =>
        instance.field.field_type === 'group' ? (
          <FieldGroup
            key={instance.id}
            instance={instance}
            groupInstances={mapToFieldInstances(
              groupInstances,
              instance.field.children
            )}
            standardYield={standardYield}
            onChange={onChange}
          />
        ) : (
          <FieldTypes
            key={instance.id}
            classes={colClass}
            instance={instance}
            onChange={onChange}
          />
        )
      )}
    </div>
  )
}
