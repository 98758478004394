import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {
  FieldInstanceProps,
  FormInstanceProps,
  FormTemplateProps
} from '../../formSchema'
import { Instruction, floor, ceil } from './FormFields/Instruction'
import { Heading } from './FormFields/Heading'
import { ProcessPoint } from './FormFields/ProcessPoint'
import { Checkbox } from './FormFields/Checkbox'
import Signature from './FormFields/Signature'
import { SOPSelect } from './FormFields/Select'
import { FieldError } from './FormFields/FieldError'
import { DateButton } from './FormFields/DateButton'
import FormatNumbersFields from './FormFields/FormatNumbers'

type FieldProps = {
  formInstance: FormInstanceProps
  instance: FieldInstanceProps
  template: FormTemplateProps
  onChange: ({ id, value }: { id: string; value: string }) => void
  hideLabel?: boolean
  classes?: string
}

export const horizontalClasses = 'form-input--horizontal'

// We need the instance to update the value on the db
// Template for template fields
// OnChange is the action for updating fields
// StandardYield is for deviation percent validation
// Hide label is for hiding the label
// Classes for columns and styling
export const FieldTypes = ({
  formInstance,
  instance,
  template,
  onChange,
  hideLabel = false,
  classes = ''
}: FieldProps) => {
  const standardYield =
    formInstance.global_fields.find((field) => field.name === 'Standard Yield')
      ?.value || ''
  const { field } = instance
  const [fieldVal, setFieldVal] = useState(instance.value || '')
  const [error, setError] = useState(instance.error || '')

  useEffect(() => {
    setError(instance.error || '')
  }, [instance.error])

  useEffect(() => {
    setFieldVal(instance.value || '')
  }, [instance.value])



  const validateInput = (e) => {
    if (field.validation_rules) {
      const { validation_type, validation_value } = field.validation_rules
      let val = e.target.value

      if (field.is_required && !val) {
        setError('Field required')
        return
      }

      if (validation_type === 'min' && val < validation_value[0]) {
        setError('Too low, min: ' + validation_value[0])
        return
      }
      if (validation_type === 'max' && val > validation_value[0]) {
        setError('Too high, max: ' + validation_value[0])
        return
      }
      if (
        validation_type === 'range' &&
        (val < validation_value[0] || val > validation_value[1])
      ) {
        setError(
          'Out of range: ' + validation_value[0] + '-' + validation_value[1]
        )
        return
      }
      if (validation_type === 'deviation-percent') {
        val = parseInt(val.replace(/,/g, ''))
        // Check if standardYield is available
        if (!standardYield) return


        const [value, deviation] = validation_value
        const lowerLimit = floor(
          (parseFloat(standardYield) * (value - deviation)) / 100
        )
        const upperLimit = ceil(
          (parseFloat(standardYield) * (value + deviation)) / 100
        )
        if (val < lowerLimit || val > upperLimit) {
          setError('Out of range: ' + validation_value[1] + '%')
          return
        }
      }

      // Clear error
      setError('')
    }
  }

  // Need to update field_instances
  const updateInstance = (e) => {
    // Use default value
    let val = instance.value

    // e is for select and signature inputs
    if (typeof e === 'string') {
      val = e
      setFieldVal(e)
    } else {
      val = e.target.value
      setFieldVal(val)
    }

    onChange({
      id: instance.id,
      value: val
    })
  }

  // Add the new condition for read-only fields
  if (field.field_type === 'read-only') return null;
  // Heading
  if (field.field_type === 'heading')
    return <Heading classes={classes} value={field.name} />
  if (field.field_type === 'heading-bold')
    return (
      <Heading classes={classes + ' font-weight-bold'} value={field.name} />
    )

  // Instructions
  if (field.field_type === 'instruction' && standardYield) {
    return (
      <Instruction
        value={field.name}
        systemOfUnits={template?.system_of_units}
        standardYield={standardYield}
      />
    )
  }

  // Note
  if (field.field_type === 'note') return <p>{field.name}</p>

  // Table Heading
  if (field.field_type === 'table-header') {
    return <p className={`${classes} header sop-label mb-0`}>{field.name}</p>
  }

  // Process Point
  if (field.field_type === 'process-point') {
    return <ProcessPoint value={field.name} description={field.description} />
  }

  // Static field
  if (field.field_type === 'static') {
    return (
      <div
        className={`form-input my-3 ${
          classes || 'col-sm-6'
        } ${horizontalClasses}`}
      >
        <p className="mb-0">
          <b>{field.name}</b>:
        </p>
        <p className="mb-0">{fieldVal || field.description}</p>
      </div>
    )
  }

  // Basic text
  if (field.field_type === 'label')
    return (
      <p className={`${classes} sop-label font-bold mb-0`}>
        {field.name.replace(/\\n/g, '\n')}
      </p>
    )

  // Instructions
  if (field.field_type === 'instruction' && standardYield) {
    return (
      <Instruction
        value={field.name}
        systemOfUnits={template?.system_of_units}
        standardYield={standardYield}
      />
    )
  }

  // Format Number with commas
  if (field.field_type === 'number-format-comma') {
    return (
      <FormatNumbersFields
        label={field.name}
        value={fieldVal}
        onChange={updateInstance}
        onBlur={validateInput}
        classes={classes}
        error={error}
      />
    )
  }

  // Checkbox
  if (field.field_type === 'checkbox') {
    return (
      <Checkbox
        label={!hideLabel ? field.name : ''}
        value={fieldVal}
        horizontal={field.features.horizontal}
        onChange={updateInstance}
        classes={classes}
      />
    )
  }

  // Date time, date, or time
  if (['datetime-btn', 'date-btn', 'time-btn'].includes(field.field_type)) {
    return (
      <DateButton
        label={!hideLabel ? field.name : ''}
        fieldType={field.field_type}
        value={fieldVal}
        onChange={updateInstance}
        classes={classes}
        error={error}
      />
    )
  }

  // Signature
  if (['signature', 'signature-role'].includes(field.field_type)) {
    return (
      <Signature
        label={!hideLabel ? field.name : ''}
        description={field.description}
        classes={classes}
        updateInstance={updateInstance}
        value={fieldVal}
        instance={instance}
        error={error}
        showRole={field.field_type === 'signature-role'}
        timestamp={instance.updated_at}
      />
    )
  }

  // ! NOT USED - Textarea
  // if (field.field_type === 'textarea') {
  //   return (
  //     <TextArea
  //       label={!hideLabel ? field.name : ''}
  //       value={fieldVal}
  //       horizontal={field.features.horizontal}
  //       onChange={updateInstance}
  //       classes={classes}
  //     />
  //   )
  // }

  // Select
  if (field.field_type === 'select' && field.features.select_options) {
    return (
      <SOPSelect
        value={fieldVal}
        options={field.features.select_options}
        onChange={updateInstance}
        label={!hideLabel ? field.name : ''}
        horizontal={field.features.horizontal}
        classes={classes}
        error={error}
      />
    )
  }

  // Default
  return (
    <div className={`tw-my-3 ${classes || 'col-sm-6'}`}>
      <div
        className={`form-input ${
          field.features.horizontal && horizontalClasses
        }`}
      >
        <div className="tw-pb-1 tw-flex tw-items-center">
          {field.name && !hideLabel && (
            <label className="mb-0">{field.name}:</label>
          )}
          {error && <FieldError error={error} />}
        </div>
        <input
          type={field.field_type}
          value={fieldVal}
          min={getMinValue(field)}
          className={`form-control form-control-input ${
            error && 'border-danger'
          }`}
          onChange={updateInstance}
          onBlur={validateInput}
        />
      </div>
    </div>
  )
}

const getMinValue = (field) => {
  const {validation_rules} = field

  if (!validation_rules) return

  const {validation_type, validation_value} = validation_rules
  if (validation_type === 'min') {
    return validation_value[0]
  } else if (validation_type === 'range') {
    return validation_value[0]
  } else if (field.field_type === 'number') {
    return 0
  } else if (field.field_type === 'date') {
    return new Date().toISOString().split('T')[0]
  }
  return undefined
}

const mapStateToProps = (state) => ({
  formInstance: state.formInstanceReducer.activeForm,
  template: state.formTemplateReducer[0]
})

export default connect(mapStateToProps)(FieldTypes)
